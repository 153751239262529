import React, { useEffect, useRef, useState } from 'react';

const CustomVideo = ({ videoUrl, saveProgress, finishVideo, setPause, pause }) => {
  const videoRef = useRef(null);
  const [isSeeking, setIsSeeking] = useState(false);

  const handlePause = (event) => {
    if (!isSeeking) {
      const progress = event.target.currentTime;
      saveProgress(progress);
      setPause(true);
    }
  };

  const handleEnded = () => {
    finishVideo();
  };


  const handleSeeking = () => {
    setIsSeeking(true);
  };

  useEffect(() => {
    if (!pause) {
      console.log("Playing video");
      const playPromise = videoRef.current.play();
      
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            // Reproducción automática exitosa
            console.log("El video se está reproduciendo");
          })
          .catch(error => {
            // Reproducción automática bloqueada
            console.error("Falló la reproducción automática:", error);
          });
      }
    } else {
      console.log("Pausing video");
      videoRef.current.pause();
    }
  }, [pause]);

  return (
    <div>
      <video
        ref={videoRef}
        controls
        width="100%"
        height="auto"
        onPause={handlePause}
        onSeeking={handleSeeking}
        onEnded={handleEnded}
      >
        <track kind="captions" />
        <source src={videoUrl} type="video/mp4" />
      </video>
    </div>
  );
};

export default CustomVideo;
