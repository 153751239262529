import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { fil } from 'date-fns/locale';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const BASE_URL = '/api/questions';

const initialState = {
  isLoading: false,
  error: null,
  questions: [{id:1, title: 'uploadLink 1', objective: 'description 1', desctiption: 'url 1'}],
  question: null,
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: [0, 200],
    rating: '',
  },
};

const slice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET questions
    getIndexSuccess(state, action) {
      state.isLoading = false;
      state.questions = action.payload;
    },

    // GET Question
    getSingleSuccess(state, action) {
      state.isLoading = false;
      state.question = action.payload;
    },

    // Create Question
    createQuestionSuccess(state, action) {
      state.isLoading = false;
      state.question = action.payload;
    },

    // edit Question
    editQuestionSuccess(state, action) {
      state.isLoading = false;
      state.question = action.payload;
    },

    // delete Question
    deleteQuestion(state, id) {
      state.isLoading = false;
      state.questions = state.questions.filter((s) => s.id !== id);
    },

    // SET SECTIONS
    setQuestions(state, action) {
      state.questions = action.payload;
    },

    //  SORT & FILTER Question
    sortByQuestion(state, action) {
      state.sortBy = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function getQuestions(filter) {
  console.log("Filter")
  console.log(filter)
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(BASE_URL, {params: filter});
      dispatch(slice.actions.getIndexSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getQuestion(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${BASE_URL}/${id}`);
      console.log("getQuestion response", response)
      dispatch(slice.actions.getSingleSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function createQuestion(data) {
  try {
    const response = await axios.post(BASE_URL, data);
    return response.data?.id;
  } catch (error) {
    console.error("Error al crear la pregunta:", error);
    throw error;
  }
}

export function updateQuestion(data, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${BASE_URL}/${id}`, data);
      dispatch(slice.actions.getSingleSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteQuestion(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`${BASE_URL}/${id}`);
      dispatch(slice.actions.deleteQuestionSuccess(id));
      // Actualiza el estado con las secciones después de eliminar una
      // utilizando la acción 'setQuestions'
      dispatch(slice.actions.deleteQuestions(id));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
