import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  employees: [],
  uploadLink: null,
  sortBy: null,
  filters: {
    gender: []
  },
};

const slice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET employees
    getIndexSuccess(state, action) {
      state.isLoading = false;
      state.employees = action.payload;
    },

    //  SORT & FILTER Employees
    sortByEmploye(state, action) {
      state.sortBy = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function getEmployees(token) {
  console.log("dispatching getEmployees")
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/employee/resume_upload?token=${token}`);
      dispatch(slice.actions.getIndexSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
