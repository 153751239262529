import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import confetti from 'canvas-confetti';
// form 
import { useForm } from 'react-hook-form';
// @mui
import { useMemo, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Container, Grid, Card, Stack, Box, TextField, Autocomplete, Button, Typography, Select, MenuItem, InputLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { PATH_MANAGER } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useSettings from '../../hooks/useSettings';
// _mock_
import { _appFeatured, _appAuthors, _appInstalled, _appRelated, _appInvoices } from '../../_mock';
// components
import Page from '../../components/Page';
import { FormProvider, RHFTextField, RHFSelect } from '../../components/hook-form';
// sections
import {
  AppWidget,
  EnterpriceWelcome,
  AppFeatured,
  AppNewInvoice,
  AppTopAuthors,
  AppTopRelated,
  AppAreaInstalled,
  AppWidgetSummary,
  AppCurrentDownload,
  AppTopInstalledCountries,
} from '../../sections/@dashboard/general/app';

import { useDispatch } from '../../redux/store';
// assets
import { SeoIllustration } from '../../assets';
import { createEnterprise, changeEnterprise } from '../../redux/slices/enterprise';
import useUserContext from '../../hooks/useUserContext';

// ----------------------------------------------------------------------
EnterpriceForm.propTypes = {
  formData: PropTypes.object,
};
export default function EnterpriceForm() {
  const { user } = useAuth();
  const defaultValues = {
    name:  '',
    capacity: '',
    institusion_type: '',
    country: '',
    language: ''
  }

  const dispatch = useDispatch();
  const [formData, setFormData] = useState(defaultValues)
  const { SetCurrentEnterprise, setSession } = useUserContext();

  const NewUserSchema = Yup.object().shape({
    name: Yup.mixed().nullable().required('nombre empresa es requerido'),
    capacity: Yup.mixed().nullable().required('Numero empleados empresa es requerido'),
    institusion_type: Yup.mixed().nullable().required('Tipo institucion es requerido'),
    country: Yup.mixed().nullable().required('Pais es requerido'),
    language: Yup.mixed().nullable().required('Lenguaje es requerido'),
  });
  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const theme = useTheme();

  const handleCreate = async (data) => {
    console.log(data)
    console.log("Submiting data")
    dispatch(createEnterprise({enterprise: data}, SetCurrentEnterprise)).then(() => {
      navigate('/manager/home');
    })
  };

  const { themeStretch } = useSettings();

  const navigateDashboard = () => {
    navigate('/manager/home');
  }

  const useThrowConfettiOnce = () => {
    useEffect(() => {
      // Throw confetti when the component mounts
      confetti({
        particleCount: 100,
        spread: 200,
        origin: { y: 0.6 }
      });
      
      // Clean up function to stop confetti after a short delay
      const cleanup = () => {
        setTimeout(() => {
          confetti.reset();
        }, 3000); // Stop confetti after 2 seconds
      };
  
      // Call the cleanup function when the component unmounts
      return cleanup;
    }, []); // Empty dependency array ensures this effect runs only once on mount
  };
   
  useThrowConfettiOnce();

  return (
    <Page title="General: App">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid xs={12}>
          <Grid item xs={12}>
            <EnterpriceWelcome
              title={`Felicidades!\n Comencemos el viaje  ${user?.name}`}
              description="Con klerai, Crea o Encuentra el mejor contenido y comparte con tus estudiantes o colaboradores."
              img={
                <SeoIllustration
                  sx={{
                    p: 2,
                    width: 250,
                    margin: { xs: 'auto', md: 'inherit' },
                  }}
                />
              }
            />
          </Grid>
          <Grid>
            <FormProvider methods={methods}>
              <Card sx={{ p: 3 }}>
                <Box
                        sx={{
                          display: 'grid',
                          columnGap: 2,
                          rowGap: 5,
                          gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                        }}
                        spacing={3}
                      >
                      <Typography gutterBottom variant="h4" sx={{ whiteSpace: 'pre-line', marginTop: 1 }}>
                        Comencemos, creando la Empresa o Institución
                      </Typography>
                      <Grid item md={12}>
                        <RHFTextField name="name" label="Nombre institucion" />
                      </Grid>
                      <Grid item md={6}>
                        <RHFSelect
                          labelId="select-institusion_type"
                          id="select-institusion_type"
                          name="institusion_type"
                          label="Tipo Institución"
                          fullWidth
                        >
                          <option value=""/>
                          <option value='school'>Colegio</option>
                          <option value='university'>Universidad</option>
                          <option value='company'>Empresa</option>
                        </RHFSelect>
                      </Grid>
                      <Grid item md={12}>
                        <RHFTextField name="capacity" label="Numero posibles estudiantes (aprox)" />
                      </Grid>
                      <Grid item md={6}>
                        <RHFSelect
                          labelId="select-country"
                          id="select-country"
                          name="country"
                          label="País"
                          fullWidth
                        >
                          <option value=""/>
                          <option value='chile'>Chile</option>
                          <option value='argentina'>Argentina</option>
                        </RHFSelect>
                      </Grid>
                      <Grid item md={6}>
                        <RHFSelect
                          labelId="select-language"
                          id="select-language"
                          name="language"
                          label="Lenguaje"
                          fullWidth
                        >
                          <option value=""/>
                          <option value="CL">Español</option>
                          <option value="EN">Ingles</option>
                        </RHFSelect>
                      </Grid>
                    <LoadingButton
                      size="large"
                      variant="contained"
                      loading={isSubmitting}
                      onClick={handleSubmit(handleCreate)}
                    >
                      Siguiente
                    </LoadingButton>
                    <LoadingButton
                      size="large"
                      variant="outlined"
                      loading={isSubmitting}
                      onClick={navigateDashboard}
                    >
                      Omitir por ahora
                    </LoadingButton>
                </Box>
              </Card>
            </FormProvider>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
