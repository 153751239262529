import { useGoogleLogin } from '@react-oauth/google';
// import Image from '../../../components/Image';
import GoogleButton from 'react-google-button'

// hooks
import useAuth from '../../../hooks/useAuth';

export default function LoginWithGoogle({roleType, isTeacher}) {
  const { google_login } = useAuth();

  // const methods = useForm({
  //   resolver: yupResolver(LoginSchema),
  //   defaultValues,
  // });

  // const {
  //   reset,
  //   setError,
  //   formState: { errors, isSubmitting },
  // } = methods;


  const googleLogin = useGoogleLogin({
    onSuccess: tokenResponse => {
      // console.log(tokenResponse);
      google_login(tokenResponse.access_token, roleType, isTeacher);
    },
  });

  return (

    <GoogleButton
      onClick={googleLogin}
      style={{width: '100%'}}
    />

    // <button onClick={googleLogin}>
    //   <Image visibleByDefault disabledEffect src="/assets/illustrations/google-logo.png" alt="login" />
    //   {<span>Sign in with Google</span>}
    // </button>

  );
}
