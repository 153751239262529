import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Grid, Stack, Button } from '@mui/material';
// Start Form
import EnterpriceForm from '../pages/enterprise_manager/EnterpriceForm';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import AdminHome from '../pages/enterprise_manager/Home';
import Login from '../pages/auth/Login';
// components
import LoadingScreen from '../components/LoadingScreen';
import {
  AppWelcome,
} from '../sections/@dashboard/general/app';
import { SeoIllustration } from '../assets';
import { PATH_MANAGER } from '../routes/paths';
// ----------------------------------------------------------------------

EnterpriseGuard.propTypes = {
  children: PropTypes.node,
};

export default function EnterpriseGuard({ children }) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  const dont_redirect = ['manager/home', '/manager/enterprice-form'];
  
  if (user.incompleate && !dont_redirect.includes(pathname)) {
    navigate(PATH_MANAGER.home.form);
  }

  return <>{children}</>;
}
