import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { fil } from 'date-fns/locale';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const BASE_URL = '/api/video_contents';

const initialState = {
  isLoading: false,
  error: null,
  videoContents: [{id:1, title: 'uploadLink 1', objective: 'description 1', desctiption: 'url 1'}],
  videoContent: null,
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: [0, 200],
    rating: '',
  },
};

const slice = createSlice({
  name: 'videoContents',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET videoContents
    getIndexSuccess(state, action) {
      state.isLoading = false;
      state.videoContents = action.payload;
    },

    // GET VideoContent
    getSingleSuccess(state, action) {
      console.log("getSingleSuccess", action)
      state.isLoading = false;
      state.videoContent = action.payload;
    },

    // Create VideoContent
    createVideoContentSuccess(state, action) {
      state.isLoading = false;
      state.upload_link = action.payload;
    },

    // edit VideoContent
    editVideoContentSuccess(state, action) {
      state.isLoading = false;
      state.upload_link = action.payload;
    },

    // delete VideoContent
    deleteVideoContent(state, id) {
      state.isLoading = false;
      state.videoContents = state.videoContents.filter((s) => s.id !== id);
    },

    // SET SECTIONS
    setVideoContents(state, action) {
      state.videoContents = action.payload;
    },

    //  SORT & FILTER VideoContent
    sortByVideoContent(state, action) {
      state.sortBy = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function getUploadLinks() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/upload_links');
      dispatch(slice.actions.getIndexSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getUploadLink(token) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/upload_links/details?token=${token}`);
      console.log("getUploadLink response", response)
      dispatch(slice.actions.getSingleSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getVideoContents(filter) {
  console.log("Filter")
  console.log(filter)
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(BASE_URL, {params: filter});
      dispatch(slice.actions.getIndexSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getVideoContent(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${BASE_URL}/${id}`);
      console.log("getVideoContent response", response)
      dispatch(slice.actions.getSingleSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createVideoContent(data) {
  console.log("DATA", data);
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(BASE_URL, data);
      dispatch(slice.actions.getSingleSuccess(response.data.videoContent));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateVideoContent(data, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${BASE_URL}/${id}`, data);
      dispatch(slice.actions.getSingleSuccess(response.data.videoContent));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteVideoContent(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`${BASE_URL}/${id}`);
      dispatch(slice.actions.deleteVideoContentSuccess(id));
      // Actualiza el estado con las secciones después de eliminar una
      // utilizando la acción 'setVideoContents'
      dispatch(slice.actions.deleteVideoContents(id));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
