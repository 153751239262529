import axios from 'axios';
// config

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL:  process.env.REACT_APP_BACKEND_HOST,
});
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
