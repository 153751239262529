import { useState } from 'react';
// @mui
import { MenuItem, Stack, Button } from '@mui/material';
// hooks
import useAuth from '../../../hooks/useAuth';
import useUserContext from '../../../hooks/useUserContext';
// components
import Image from '../../../components/Image';
import MenuPopover from '../../../components/MenuPopover';
import { useDispatch } from '../../../redux/store';
import { changeEnterprise } from '../../../redux/slices/enterprise';

// ----------------------------------------------------------------------

export default function EnterprisePopover() {
  const { user, isAuthenticated } = useAuth();
  const { SetCurrentEnterprise, currentEnterprise } = useUserContext();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    console.log(user.enterprises)
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangeEnterprise = (enterprise) => {
    console.log(enterprise)
    dispatch(changeEnterprise(enterprise.token, SetCurrentEnterprise)).then(() => {
      window.location.reload();
    });
  };

  return (
    <>
      <Button variant="outlined" color="inherit" onClick={handleOpen}>
          { currentEnterprise?.name }
      </Button>


      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {user && user.enterprises?.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === 0}
              onClick={() => handleChangeEnterprise(option)}
            >
              {option.name}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
