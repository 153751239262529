import PropTypes, { string } from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// Start Form
import EnterpriceForm from '../pages/enterprise_manager/EnterpriceForm';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import AdminHome from '../pages/enterprise_manager/Home';
import Login from '../pages/auth/Login';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  type: string,
  children: PropTypes.node,
};

export default function AuthGuard({children }) {
  const { user, isAuthenticated, isInitialized } = useAuth();

  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }
  console.log('auth')
  if (user && user.role === 'enterprise_manager' && pathname === '/dashboard') {
    if(user.incompleate){
      return <EnterpriceForm />;
    }
    return <Navigate to={'/admin/home'} />;
  }

  if (user && user.role === 'admin' && pathname === '/dashboard') {
    return <Navigate to={'/admin/home'} />;
  }
  
  if (user && user.is_teacher && pathname === '/dashboard') {
    console.log('teacher')  
    if(user.incompleate_teacher){
      return <Navigate to={'/teacher/start'} />;
    }
  }

  if (user && user.role === 'student' && pathname === '/dashboard') {
    return <Navigate to={'/student/home'} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
