// routes
import { PATH_DASHBOARD, PATH_TEACHER, PATH_STUDENT, PATH_ADMIN, PATH_MANAGER } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';


// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const navConfigTeacher = [
  // PROFESOR
  // ----------------------------------------------------------------------
  {
    subheader: 'Profesor',
    items: [
      { title: 'Home', path: PATH_TEACHER.root, icon: ICONS.dashboard },
      {
        title: 'Contenido',
        path: PATH_TEACHER.videoContent.root,
        icon: ICONS.invoice,
        children: [
          { title: 'lista', path: PATH_TEACHER.videoContent.root },
          { title: 'crear', path: PATH_TEACHER.videoContent.new }
        ],
      },
      {
        title: 'Preguntas',
        path: PATH_TEACHER.question.root,
        icon: ICONS.invoice,
        children: [
          { title: 'lista', path: PATH_TEACHER.question.root },
          { title: 'crear', path: PATH_TEACHER.question.new }
        ],
      },
      {
        title: 'Evaluaciones',
        path: PATH_TEACHER.quiz.root,
        icon: ICONS.invoice,
        children: [
          { title: 'lista', path: PATH_TEACHER.quiz.root },
          { title: 'crear', path: PATH_TEACHER.quiz.new }
        ],
      },
    ]
  },
];

export default navConfigTeacher;
