import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { fil } from 'date-fns/locale';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const BASE_URL = '/api/enterprise_areas';

const initialState = {
  isLoading: false,
  error: null,
  enterprise_areas: [{id:1, title: 'uploadLink 1', objective: 'description 1', desctiption: 'url 1'}],
  enterprise_area: null,
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: [0, 200],
    rating: '',
  },
};

const slice = createSlice({
  name: 'enterprise_areas',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET enterprise_areas
    getIndexSuccess(state, action) {
      state.isLoading = false;
      state.enterprise_areas = action.payload;
    },

    // GET EnterpriseArea
    getSingleSuccess(state, action) {
      state.isLoading = false;
      state.enterprise_area = action.payload;
    },

    // Create EnterpriseArea
    createEnterpriseAreaSuccess(state, action) {
      state.isLoading = false;
      state.enterprise_area = action.payload;
    },

    // edit EnterpriseArea
    editEnterpriseAreaSuccess(state, action) {
      state.isLoading = false;
      state.enterprise_area = action.payload;
    },

    // delete EnterpriseArea
    deleteEnterpriseArea(state, id) {
      state.isLoading = false;
      state.enterprise_areas = state.enterprise_areas.filter((s) => s.id !== id);
    },

    // SET SECTIONS
    setEnterpriseArea(state, action) {
      state.enterprise_areas = action.payload;
    },

    //  SORT & FILTER EnterpriseArea
    sortByEnterpriseArea(state, action) {
      state.sortBy = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function getEnterpriseAreas(filter) {
  console.log("Filter")
  console.log(filter)
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(BASE_URL, {params: filter});
      dispatch(slice.actions.getIndexSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getEnterpriseArea(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${BASE_URL}/${id}`);
      console.log("getEnterpriseArea response", response)
      dispatch(slice.actions.getSingleSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createEnterpriseArea(data) {
  console.log("DATA", data);
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(BASE_URL, data);
      dispatch(slice.actions.getSingleSuccess(response.data.enterprise_area));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateEnterpriseArea(data, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${BASE_URL}/${id}`, data);
      dispatch(slice.actions.getSingleSuccess(response.data.enterprise_area));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteEnterpriseArea(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`${BASE_URL}/${id}`);
      dispatch(slice.actions.deleteEnterpriseAreaSuccess(id));
      dispatch(slice.actions.deleteEnterpriseArea(id));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function sendEnterpriseUserInvitation(id, data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/enterprise_areas/${id}/send_user_invitation`, data)
      dispatch(slice.actions.getSingleSuccess(response.data.enterprise_area));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function sendEnterpriseManagerInvitation(id, data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/enterprise_areas/${id}/send_manager_invitation`, data)
      dispatch(slice.actions.getSingleSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
